import React from 'react';
import { LightGallerySchema } from './LightGallerySchema';
import BlockDataForm from '@plone/volto/components/manage/Form/BlockDataForm';

const LightGalleryData = (props) => {
  const { block, data, onChangeBlock } = props;
  const schema = LightGallerySchema({ ...props });
  console.log(props.metadata);
  return (
    <BlockDataForm
      schema={schema}
      title={schema.title}
      onChangeField={(id, value) => {
        onChangeBlock(block, {
          ...data,
          [id]: value,
        });
      }}
      formData={data}
      block={block}
    />
  );
};
export default LightGalleryData;