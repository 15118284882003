export const NewsBoxSchema = (props) => {
    return {
        title: 'News',
        fieldsets: [
            {
                id: 'default',
                title: 'Default',
                fields: ['newsurl'],
            },
        ],

        properties: {
            newsurl: {
                title: 'Url',
                description: 'Start Url für die News',
                widget: 'internal_url',
                required: true,
            },
        },
        required: [],
    };
};

export default NewsBoxSchema;