import React from 'react';
import LightGalleryData from './LightGalleryData';
import SidebarPortal from '@plone/volto/components/manage/Sidebar/SidebarPortal';

const LightGalleryEdit = React.memo((props) => {
    const { selected, onChangeBlock, block, data } = props;

    return (
        <>
            <h3>Bildergalerie</h3>
            <img
                src='/default-image.svg'
                width="400"
                height="300"
            />
            <SidebarPortal selected={selected}>
                <LightGalleryData
                    {...props}
                    data={data}
                    block={block}
                    onChangeBlock={onChangeBlock}
                />
            </SidebarPortal>
        </>
    )
})

export default LightGalleryEdit;