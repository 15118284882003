
export const LightGallerySchema = (props) => {
  return {
    title: 'Bilder Galerie',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['galleryurl', 'colNumb', 'sortOn', 'sortOrder'],
      },
    ],

    properties: {
      galleryurl: {
        title: 'Url',
        description: 'Start Url für die Galeri Bilder',
        widget: 'internal_url',
        required: true,
      },
      colNumb: {
        title: 'Spalten Anzahl',
        description: 'Anzahl der Spalten',
        widget: 'number',
        required: true,
      },
      sortOn: {
        title: 'Sortieren nach',
        description: 'Begriff zur sortierung z.B. sortable_title',
        widget: 'text',
      },
      sortOrder: {
        title: 'Sortierreihenfolge absteigend',
        description: 'Absteigend sortieren, ansonsten aufsteigend.',
        type: 'boolean',
      }
    },
    required: [],
  };
};

export default LightGallerySchema;