import React from 'react';
import { DefaultView } from '@plone/volto/components';
import PropTypes from 'prop-types';

const PersonView = props => {
  return (
    <>
      <DefaultView {...props}/>
    </>
  );
};

PersonView.propTypes = {
  content: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default PersonView;